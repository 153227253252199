/* slick-overrides.css */

/* Override the arrow color for the previous and next buttons */
.slick-prev:before{
    content: "<";
    font-family:initial!important;
    color: gray!important;
    font-weight: bold;
    font-size: 25px;
    /* margin-right: 5px; */
}
.slick-next:before {
    content: ">";
    font-family:initial!important;
    color: gray!important;
    font-weight: bold;
    font-size: 25px;
    /* margin-left: 5px; */
}

.carousel-container {
    height: 100%; /* Set your desired height */
    /* Additional styling if needed */
  }